const pjson = require('../../package.json');
const Sentry = require('@sentry/browser');
const { fetchSentryConfig } = require('./http');
const { mainConfig } = require('./variables');
const { CaptureConsole, ContextLines, Debug, ExtraErrorData, HttpClient, ReportingObserver, RewriteFrames } = require('@sentry/integrations');
const { sentryIgnoreErrors } = require('./fields');

function createIntegration(integrationName, integrationConfig) {
  let integration = null;
  switch (integrationName) {
  case 'Sentry.Replay':
    integration = new Sentry.Replay(integrationConfig);
    break;
  case 'Sentry.BrowserTracing':
    integration = new Sentry.BrowserTracing(integrationConfig);
    break;
  case 'Sentry.Integrations.Breadcrumbs':
    integration = new Sentry.Integrations.Breadcrumbs(integrationConfig);
    break;
  case 'Sentry.Integrations.Dedupe':
    integration = new Sentry.Integrations.Dedupe(integrationConfig);
    break;
  case 'Sentry.Integrations.GlobalHandlers':
    integration = new Sentry.Integrations.GlobalHandlers(integrationConfig);
    break;
  case 'Sentry.Integrations.InboundFilters':
    integration = new Sentry.Integrations.InboundFilters(integrationConfig);
    break;
  case 'Sentry.Integrations.LinkedErrors':
    integration = new Sentry.Integrations.LinkedErrors(integrationConfig);
    break;
  case 'Sentry.Integrations.TryCatch':
    integration = new Sentry.Integrations.TryCatch(integrationConfig);
    break;
  case 'Sentry.Integrations.HttpContext':
    integration = new Sentry.Integrations.HttpContext(integrationConfig);
    break;
  case 'Sentry.Integrations.ModuleMetadata':
    integration = new Sentry.ModuleMetadata(integrationConfig);
    break;
  case 'CaptureConsole':
    integration = new CaptureConsole(integrationConfig);
    break;
  case 'ContextLines':
    integration = new ContextLines(integrationConfig);
    break;
  case 'Debug':
    integration = new Debug(integrationConfig);
    break;
  case 'HttpClient':
    integration = new HttpClient(integrationConfig);
    break;
  case 'ExtraErrorData':
    integration = new ExtraErrorData(integrationConfig);
    break;
  case 'ReportingObserver':
    integration = new ReportingObserver(integrationConfig);
    break;
  case 'RewriteFrames':
    integration = new RewriteFrames(integrationConfig);
    break;
  default:
    console.log({'status':'error', 'message':'Invalid Sentry Configs'});
  }

  return integration;
}

const createSentryConfig = (sentryConfig,  merchant_id) => {
  const integrations = [];

  if (sentryConfig.integrations) {
    for (const integrationName in sentryConfig.integrations) {
      const integrationConfig = sentryConfig.integrations[integrationName];
      if (integrationConfig !== null) {
        const integration = createIntegration(integrationName, integrationConfig);
        if (integration) {
          integrations.push(integration);
        }
      }
    }
  }

  const finalConfig = {
    release: pjson.version,
    merchant: merchant_id,
    ignoreErrors: sentryIgnoreErrors
  };

  if(integrations.length) {
    finalConfig.integrations = integrations;
  }

  for (const key in sentryConfig) {
    if (key !== 'integrations') {
      finalConfig[key] = sentryConfig[key];
    }
  }

  return finalConfig;
};

const setupSentry = async (configs) => {
  let fetchedConfigs = null;
  
  if (localStorage.getItem('SDK_STUDIO_CONFIGS')) {
    fetchedConfigs = JSON.parse(localStorage.getItem('SDK_STUDIO_CONFIGS'));
  }

  mainConfig.shadowRootClosed = fetchedConfigs && fetchedConfigs.init && fetchedConfigs.init.shadowRoot === false ?  false : true;
  mainConfig.captureAll = fetchedConfigs && fetchedConfigs.init && fetchedConfigs.init.captureAll === true ? true : false;

  Sentry.setTag('SDK', 'ottu-js-sdk');
  if(!fetchedConfigs || !fetchedConfigs.sentry) {
    Sentry.init({
      'release': pjson.version,
      'dsn': process.env.DEFAULT_DSN,
      'sampleRate': 0.2,
      ignoreErrors: sentryIgnoreErrors
    });
  } else {
    const sentryConfigs = createSentryConfig(fetchedConfigs.sentry, configs.merchant_id);
    Sentry.init({
      ...sentryConfigs,
    });
  }

  const response = await fetchSentryConfig(configs.merchant_id, configs.apiKey);

  if (!response) {
    localStorage.setItem('SDK_STUDIO_CONFIGS', null);
  } else {  
    localStorage.setItem('SDK_STUDIO_CONFIGS', JSON.stringify(response));
  }
};
exports.setupSentry = setupSentry;